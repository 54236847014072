import { ref, computed } from 'vue'
var path = require('path');

export default function () {
    const serviceListRef = ref('[]');
    const list = [{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/gongshang.png',
        name: '工商注册、代理记账服务',
        text: '公司注册、变更、转让、注消代理及与工商局事务相关的工商事务咨询服务。'
    },
    {
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/zhishi.png',
        name: '知识产权、专利服务',
        text: '专利业务、商标业务、软著业务、无形资产价值评估、知识产权保护与应用。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/cahungye.png',
        name: '创业辅导服务',
        text: '提供供创业管理和创业培训的第三方服务机构。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/renli.png',
        name: '人力资源服务',
        text: '人力资源外包、派遣、培训、招聘、测评、管理咨询、战略咨询、猎头服务。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/tixi.png',
        name: '体系、产品认证服务',
        text: '体系认证、产品认证（国家强制性产品认证、非强制性产品认证）。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/jinrong.png',
        name: '金融服务',
        text: '投融资、贷款、储蓄、信贷、证券买卖、商业保险和金融信息咨询等。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/shangwu.png',
        name: '商务服务',
        text: '包括信息咨询、广告服务以及公关服务等。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/jishu.png',
        name: '技术支持、管理咨询',
        text: '基础咨询、功能咨询和产业咨询。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/falv.png',
        name: '法律咨询服务',
        text: '有关法律事务问题作出解释、说明，提出建议和解决方案。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/xiangmu.png',
        name: '项目申报服务',
        text: '双创载体、入驻企业等项目申报，高新技术企业认定、研发补助等项目申报。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/zhihui.png',
        name: '智慧物业服务',
        text: '园区安全及防护管理、设施管理、租赁及物业费用管理、园区保洁等。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/caichan.png',
        name: '财产保险服务',
        text: '企业财产保险、跨境贸易保险、货物运输保险等。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/xiezuo.png',
        name: '创业培训服务',
        text: '创新创业、项目路演、投融资、办公技 能提升、情绪控制、团队协作。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/jiankan.png',
        name: '健康医疗保障服务',
        text: '健康检查、健康咨询，急救箱、医疗站。'
    },{
        path: 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/shalong.png',
        name: '沙龙、论坛活动',
        text: '创新创业高峰论坛、主题沙龙、节日活动。'
    }]
    serviceListRef.value = list;
    return {
        serviceListRef
    }
}
