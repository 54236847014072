<template>
  <div class="service-container">
    <div class="service-top container-width" style="padding-bottom: 20px">
      <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/zhengtouqifu.png" />
    </div>
    <div class="service-bottom">
      <div class="service-item" v-for="item in serviceListRef" :key="item.name">
        <img :src="item.path" />
        <div class="text-con">
          <div class="title title-text" style="margin-bottom:14px">{{ item.name }}</div>
          <div class="title-con">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getServiceList from "./competition/getServiceList";
export default {
  setup() {
    return {
      ...getServiceList(),
    };
  },
};
</script>

<style scoped>
.service-bottom{
  display: flex;
  flex-wrap: wrap;
  max-width: 1420px;
  justify-content:space-between;
  margin: 0 auto;
}
@media screen and (max-width:1336px) {
  .service-bottom{
    padding: 0 40px;
    justify-content:space-around;
  }
}
@media screen and (max-width:1470px) {
  .title-text{
    margin-left: 0px  ;
  }
}
.service-top img {
  width: 100%;
}
.service-item {
  position: relative;
  overflow: hidden;
  width: 445px;
  height: 190px;
  margin-bottom: 20px;
}
.title {
  font-size: 20px;
  color: #000000;
}
.service-item img {
  position: absolute;
  width: 100%;
}
.title-con {
  font-size: 18px;
  color: #555;
  max-width: 306px;
}
.text-con {
  position: absolute;
  left: 100px;
  top:51px;
  flex-wrap: wrap;
  max-width: 310px;
  height: 100%;
  align-content:center;
}
</style>
